@tailwind base; // reset browser defaults

@layer base {
  html {
    @apply font-proxima font-thin;
  }
  html,
  body {
    @apply h-full w-full;
    @apply overflow-x-hidden;
  }
  select {
    // Reset browser default (e.g. on ios)
    @apply bg-transparent;
  }
  // you can add some base styles here, e.g. h1, h2, h3, p, a
}
